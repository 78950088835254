$icon-font-path: '../fonts/';

// bower:scss
@import "bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
// endbower
@import "bower_components/rouble.font/rouble.scss";
@import "bower_components/font-awesome/scss/font-awesome.scss";
@import url(https://fonts.googleapis.com/css?family=Scada:400,700&subset=latin,cyrillic);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700,400italic,600&subset=latin,cyrillic);

@font-face {
  font-family: 'fontin sans';
  src: url('../fonts/fontin_sans_cr_regular.eot');
  src: url('../fonts/fontin_sans_cr_regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/fontin_sans_cr_regular.woff2') format('woff2'),
  url('../fonts/fontin_sans_cr_regular.woff') format('woff'),
  url('../fonts/fontin_sans_cr_regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'fontin sans';
  src: url('../fonts/fontinsans_cyrillic_b_46b.eot');
  src: url('../fonts/fontinsans_cyrillic_b_46b.eot?#iefix') format('embedded-opentype'),
  url('../fonts/fontinsans_cyrillic_b_46b.woff2') format('woff2'),
  url('../fonts/fontinsans_cyrillic_b_46b.woff') format('woff'),
  url('../fonts/fontinsans_cyrillic_b_46b.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'fontin sans';
  src: url('../fonts/fontinsans_cyrillic_i_46b.eot');
  src: url('../fonts/fontinsans_cyrillic_i_46b.eot?#iefix') format('embedded-opentype'),
  url('../fonts/fontinsans_cyrillic_i_46b.woff2') format('woff2'),
  url('../fonts/fontinsans_cyrillic_i_46b.woff') format('woff'),
  url('../fonts/fontinsans_cyrillic_i_46b.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

body {
  font-family: 'Scada', sans-serif;
  font-size: 16px;
  color: #2B2A29;
}

header {
  padding: 33px 0 65px;
  position: relative;
  @media (max-width: $screen-xs-max) {
    padding: 20px 0;
  }
}
.logo {
  display: block;
  background: url(../images/logo.png) no-repeat 0 0/contain;
  width: 210px;
  height: 66px;
  margin: 0 0 0 15px;
  @media (max-width: $screen-sm-max) {
    margin: 0;
  }
  @media (max-width: $screen-xs-max) {
    margin: 0 auto;
  }
}
.site-info {
  margin: 16px 0 0 0;
  line-height: 23px;
  &__name {
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 700;
  }
  &__slogan {
    font-size: 18px;
    letter-spacing: 0.02em;
  }
  @media (max-width: $screen-xs-max) {
    text-align: center;
  }
}
.cart {
  display: inline-block;
  line-height: 23px;
  background: url(../images/header-cart-icon.png) no-repeat 0 0;
  padding: 0 0 0 62px;
  color: #2B2A29;
  margin: 16px 0 0 2px;
  &:focus,
  &:hover {
    text-decoration: none;
    color: #2B2A29;;
  }
  &__title {
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 700;
  }
  &__goods {
    span {
      color: #007EC0;
    }
  }
  @media (max-width: $screen-sm-max) {
    margin-left: 0;
  }
  @media (max-width: $screen-xs-max) {
    margin: 0 auto;
    max-width: 230px;
    display: block;
  }
}
.contacts {
  margin: 10px 0 0 35px;
  @media (max-width: $screen-sm-max) {
    margin: 0;
    text-align: center;
  }
  &__tel {
    font-size: 24px;
    color: #2B2A29;
    text-decoration: none;
    font-weight: 700;
    &:focus,
    &:hover {
      color: #2B2A29;
      text-decoration: none;
    }
  }
  &__email {
    color: #007EC0;
    text-decoration: underline;
    &:focus,
    &:hover {
      color: #007EC0;
      text-decoration: none;
    }
  }
}
.main-nav-wrapper {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  bottom: -33px;
  z-index: 10;
  @media (max-width: $screen-xs-max) {
    position: relative;
    bottom: 0;
  }
}
.burger {
  margin-top: 20px;
  font-size: 40px;
  cursor: pointer;
}
.main-nav {
  display: inline-block;
  margin: 0;
  padding: 0;
  list-style-type: none;
  background: #2B2A29;
  font-size: 18px;
  font-weight: 700;
  @include skew(-16deg, 0);
  text-align: center;
  li {
    display: inline-block;
    vertical-align: top;
    @media (max-width: $screen-xs-max) {
      display: block;
    }
  }
  a {
    background: #2B2A29;
    text-decoration: none;
    color: #F2F2F2;
    display: block;
    width: inherit;
    height: inherit;
    padding: 23px 29px 22px;
    @media (max-width: $screen-md-max) {
      padding-left: 15px;
      padding-right: 15px;
    }
    @media (max-width: $screen-sm-max) {
      padding: 18px 6px
    }
    span {
      display: block;
      @include skew(16deg, 0);
      @media (max-width: $screen-sm-max) {
        @include skew(0, 0);
      }
    }
    &:focus,
    &:hover {
      background: #00A0E2;
      color: #ffffff;
    }

  }
  &__request {
      a {
        text-transform: uppercase;
        padding: 23px 57px 20px;
        background: #00A0E2;
        &:hover {
          background: darken(#00A0E2, 5%);
        }
        @media (max-width: $screen-md-max) {
          padding-left: 30px;
          padding-right: 30px;
        }
        @media (max-width: $screen-sm-max) {
          padding: 18px 10px;
        }
      }
  }
  &__icon {
    display: inline-block;
    vertical-align: text-bottom;
    margin: 0 20px 0 0;
  }
  @media (max-width: $screen-sm-max) {
    font-size: 16px;
    @include skew(0, 0);
  }
  @media (max-width: $screen-xs-max) {
    display: none;
    &.visible {
      display: block;
    }
  }
}
.main-slider-wrapper {
  position: relative;
}
.main-slider {
  height: 790px;
  &__img {
    display: block;
    position: relative;
  }
  .owl-item.active {
    .main-slider__img {
      z-index: 10;
    }
  }
  @media (max-width: $screen-md-max) {
    height: 400px;
  }
  @media (max-width: $screen-sm-max) {
    height: 400px;
  }
  @media (max-width: $screen-xs-max) {
    height: 300px;
  }
}
.main-slider-nav {
  .owl-prev,
  .owl-next {
    z-index: 20;
    cursor: pointer;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    background: url(../images/main-slider-nav.png) no-repeat;
    width: 39px;
    height: 75px;
    background-position: -39px 0;
    &:hover {
      background-position: 0 0;
    }
  }
  .owl-prev {
    left: 50px;
    &:hover {
      @include scale(-1,1);
    }
  }
  .owl-next {
    right: 50px;
    @include scale(-1,1);
    &:hover {
      @include scale(1,1);
    }
  }
}
.main-slider-dots {
  position: absolute;
  bottom: 15px;
  z-index: 30;
  width: 100%;
  text-align: center;
  .owl-dot {
    display: inline-block;
    vertical-align: middle;
    width: 15px;
    height: 15px;
    margin: 0 8px;
    &.active {
      span {
        margin-top: 0;
        background: #4AB3F1;
        width: 13px;
        height: 13px;
        border: 1px solid rgba(255,255,255,0.5);
      }
    }
    span {
      display: block;
      margin-top: 2px;
      height: 10px;
      width: 10px;
      border-radius: 100%;
      background: #ffffff;
      cursor: pointer;
      border: 1px solid #A07158;

    }
  }
}
.catalog {

}
.catalog-tabs {
  background: #D7E2EA;
  text-align: center;
  padding: 37px 0 0;
  &--inner {
    padding-top: 86px;
  }
  @media (max-width: $screen-sm-max) {
    padding-top: 20px;
    &--inner {
      padding-top: 50px;
    }
  }
  @media (max-width: $screen-xs-max) {
    padding-top: 10px;
  }

}
.catalog-nav {
  display: inline-block;
  margin: 0;
  padding: 0;
  list-style-type: none;
  background: #909CA4;
  @include skew(-20deg, 0);
  font-size: 18px;
  @media (max-width: $screen-xs-max) {
    display: block;
    @include skew(0, 0);
  }
  &__icon {
    position: absolute;
    display: none;
    @include skew(20deg, 0);
    &--1 {
      top: -8px;
      left: 19px;
    }
    &--2 {
      top: -11px;
      left: -10px;
    }
    &--3 {
      top: -3px;
      left: 13px;
    }
    &--4 {
      top: -21px;
      left: -14px;
    }
  }
  li {
    display: inline-block;
    background: #909CA4;
    vertical-align: baseline;
    position: relative;
    &:first-child {
      a {
        &:before {
          display: none;
        }
      }
    }
    @media (max-width: $screen-xs-max) {
      display: block;
    }
    &.active {
      .catalog-nav__icon {
        display: block;
        @media (max-width: $screen-md-max) {
          display: none;
        }
      }
      a {
        background: #ffffff;
        color: #2B2A29;
        box-shadow: 0px -3px 0 0 #ffffff;
        &:before {
          display: none;
        }
      }
    }
    a {
      font-weight: 700;
      background: #909CA4;
      text-decoration: none;
      color: #F2F2F2;
      display: block;
      width: inherit;
      height: inherit;
      padding: 19px 34px 16px 63px;
      &:before {
        content: '';
        position: absolute;
        width: 1px;
        height: 40px;
        top: 0;
        bottom: 0;
        margin: auto;
        left: -5px;
        background: #9FA8AF;
        display: block;
        @media (max-width: $screen-xs-max) {
          display: none;
        }
      }
      span {
        display: block;
        @include skew(20deg, 0);
        @media (max-width: $screen-xs-max) {
          @include skew(0, 0);
        }
      }
      @media (max-width: $screen-md-max) {
        padding: 19px 19px 16px 29px
      }
      @media (max-width: $screen-sm-max) {
        font-size: 14px;
        padding: 15px;
      }
    }
  }
}
.catalog-content {
  padding: 40px 0 53px;
  @media (max-width: $screen-xs-max) {
    padding: 20px 0;
  }
}
.filter {
  margin: 0;
  padding: 0;
  list-style-type: none;
  > li {
    position: relative;
    border-bottom: 1px solid #EFF3F7;
    padding: 20px 0 15px;
    &.active {
      .filter-section-title {
        &:before {
          content: '\f107';
        }
      }
      .filter-section {
        display: block;
      }
    }
  }
}
.filter-reset {
  color: #96A3AB;
  font-size: 14px;
  display: inline-block;
  border-bottom: 1px dashed #96A3AB;
  line-height: 16px;
  margin: 20px 0 0;
  &:hover {
    color: #96A3AB;
    border-bottom-color: transparent;
    text-decoration: none;
  }
  .fa {
    margin: 0 5px 0 0;
  }
}
.filter-section-title {
  color: #2B2A29;
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
  display: inline-block;
  position: relative;
  margin: 0 0 0 36px;
  padding: 0 25px 0 0;
  &:focus,
  &:hover {
    color: #2B2A29;
    text-decoration: none;
  }
  &:before {
    content: '\f105';
    font-family: 'FontAwesome';
    display: block;
    position: absolute;
    top: -1px;
    left: -26px;
    color: #96A2AB;
    font-weight: 400;
  }
}
.filter-section {
  font-family: 'Open Sans', sans-serif;
  display: none;
  margin: 0 0 0 36px;
  padding: 27px 0 0 31px;
  font-size: 16px;
  list-style-type: none;
  li {
    margin: 0 0 8px;
  }
  &__checkbox {
    display: none;
    &:checked + label {
      &:before {
        content: '\f00c';
        background: #645F5B;
      }
    }
  }
  &__label {
    font-weight: 400;
    margin: 0;
    cursor: pointer;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      width: 18px;
      height: 18px;
      background: #EFF3F6;
      border: 1px solid #D7E2EA;
      display: block;
      vertical-align: middle;
      font-family: 'FontAwesome';
      color: #FBAA29;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      top: 2px;
      left: -30px;
    }
  }
  &__amount {
    color: #00A0E3;
  }
}
.filter-panel {
  color: #93A0A9;
  font-family: 'Open Sans', sans-serif;
  font-style: italic;
  padding: 35px 0 15px;
  border-bottom: 1px solid #EFF3F7;
  @media (max-width: $screen-sm-max) {
    padding-top: 15px;
  }
}
.sorting {
  float: left;
  &__caption {
    display: inline-block;
    vertical-align: middle;
    margin: 0 10px 0 0;
  }
}
.catalog-view {
  float: right;
  @media (max-width: $screen-sm-max) {
    float: left;
    margin-top: 15px;
  }
  &__icon {
    display: inline-block;
    vertical-align: middle;
    width: 27px;
    height: 27px;
    margin: 0 0 0 15px;
    background: url(../images/catalog-view.png) no-repeat;
    &--1 {
      background-position: 0 0;
      &.active,
      &:hover {
        background-position: -27px 0;
      }
    }
    &--2 {
      background-position: 0 -27px;
      &.active,
      &:hover {
        background-position: -27px -27px;
      }
    }
    &--3 {
      background-position: -54px 0;
      &.active,
      &:hover {
        background-position: -54px -27px;
      }
    }
  }
}
.catalog-items {
  padding: 35px 0 15px;
  border-bottom: 1px solid #EFF3F7;
  @media (max-width: $screen-sm-max) {
    padding-top: 0;
  }
}
.more-products {
  background: transparent;
  border: 1px solid #CAD0D4;
  border-radius: 3px;
  color: #93A0A9;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  padding: 7px 17px;
  margin: 12px 0 0;
  .fa {
    margin: 0 0 0 5px;
  }
  &--related {
    background: #E1E8E7;
  }
}
.product-item {
  text-align: left;
  border-radius: 3px;
  font-family: 'Open Sans', sans-serif;
  height: 420px;
  position: relative;
  margin: 0 0 25px;
  background: #ffffff;
  @media (max-width: $screen-md-max) {
    height: 440px;
  }
  &--related {
    padding: 0 50px 0;
    height: 520px;
    .action-icon {
      top: 25px;
      left: 25px;
    }
    .product-item__bottom {
      left: 50px;
      right: 50px;
      bottom: 60px;
      @media (max-width: $screen-md-max) {
        bottom: 25px;
      }

    }
    .product-item__img-wrapper {
      height: 260px;
      margin-top: 30px;
    }
  }
  &--list {
    height: auto;
    margin: 0;
    .product-item__bottom {
      position: relative;
    }
    .to-cart,
    .from-cart{
      float: none;
      margin-left: 40px;
    }
  }
  &__bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  &__img-wrapper {
    height: 224px;
    position: relative;
    img {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      max-width: 100%;
    }
  }
  &__title {
    font-size: 18px;
    color: #02A0E3;
    text-decoration: underline;
    display: inline-block;
    margin: 45px 0 0;
    font-weight: 600;
    &:hover,
    &:focus {
      color: #02A0E3;
      text-decoration: none;
    }
  }
  &__caption {
    font-size: 14px;
    margin: 9px 0 15px;
    line-height: 19px;
  }

}
.cost {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  font-size: 24px;
  color: #FFA800;
  font-weight: 700;
  &--discount {
    color: #D71919;
    font-size: 22px;
  }
  @media (max-width: $screen-md-max) {
    font-size: 20px;
    margin-top: 4px;
  }
}
.old-cost {
  position: absolute;
  color: #93A0A9;
  font-size: 16px;
  text-decoration: line-through;
}
.to-cart,
.from-cart {
  background: url(../images/add-to-cart-icon.png) no-repeat 14px 7px;
  float: right;
  display: inline-block;
  vertical-align: middle;
  font-family: 'fontin sans', sans-serif;
  color: #FFFFFF;
  border: none;
  border-radius: 3px;
  font-style: italic;
  padding: 10px 25px 8px 50px;
  &:focus,
  &:hover {
    text-decoration: none;
    color: #ffffff;
  }
}
.to-cart {
  background-color: #00A0E3;
  &:focus,
  &:hover {
    background-color: darken(#00A0E3, 5%);
  }
}
.from-cart {
  background-color: #93A0A9;
  &:focus,
  &:hover {
    background-color: darken(#93A0A9, 5%);
  }
}
.action-icon {
  display: block;
  position: absolute;
  width: 47px;
  height: 46px;
  z-index: 10;
  top: 0;
  left: 0;
}
.advantages {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+25,f8f4ef+50,c9d5d4+100 */
  background: #ffffff; /* Old browsers */
  background: -moz-linear-gradient(top,  #ffffff 0%, #ffffff 25%, #f8f4ef 50%, #c9d5d4 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  #ffffff 0%,#ffffff 25%,#f8f4ef 50%,#c9d5d4 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  #ffffff 0%,#ffffff 25%,#f8f4ef 50%,#c9d5d4 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#c9d5d4',GradientType=0 ); /* IE6-9 */
  box-shadow: 0 -3px 16px 0 #DDDEDF;
  padding: 60px 0 45px;
  &__logo {
    display: block;
  }
  &__title {
    font-family: 'fontin sans', sans-serif;
    font-size: 48px;
    font-weight: 700;
    margin: 27px 0 0;
    @media (max-width: $screen-xs-max) {
     font-size: 40px;
    }
  }
  &__list {
    padding: 0;
    list-style-type: none;
    font-size: 17px;
    font-family: 'Open Sans', sans-serif;
    margin: 36px 0 0 0;
    li {
      padding: 0 0 0 37px;
      margin: 0 0 24px;
      position: relative;
      &:before {
        content: '';
        display: block;
        width: 19px;
        height: 1px;
        background: #FFA800;
        position: absolute;
        top: 12px;
        left: 0;
      }
    }
  }
}
footer {
  background: #0F0E0D;
  padding: 60px 0;
  @media (max-width: $screen-xs-max) {
    padding: 20px 0;
  }
}
.copyright {
  color: #7A8B94;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  margin: 10px 0 0;
  @media (max-width: $screen-xs-max) {
    text-align: center;
  }
}
.developer {
  text-align: right;
  color: #7A8B94;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  margin: 7px 0 0;
  @media (max-width: $screen-xs-max) {
    text-align: center;
  }
  img {
    display: inline-block;
    margin: 0 0 0 5px;
    vertical-align: bottom;
  }
}
.social {
  padding: 0;
  list-style-type: none;
  text-align: center;
  li {
    display: inline-block;
    vertical-align: middle;
    margin: 0 5px 5px;
  }
  img {
    display: block;
    width: auto;
    height: auto;
  }
  @media (max-width: $screen-xs-max) {
    padding-top: 20px;
  }
}
.crumbs {
  margin: 0;
  padding: 40px 0 0;
  list-style-type: none;
  font-family: 'Open Sans', sans-serif;
  color: #93A0A9;
  li {
    display: inline-block;
    &:before {
      content: '/';
      margin: 0 5px;
    }
    &:first-child {
      &:before {
        display: none;
      }
    }
  }
  a {
    color: #93A0A9;
    text-decoration: underline;
    &:focus,
    &:hover {
      color: #93A0A9;
      text-decoration: none;
    }
  }
}
.product-detail {
  padding: 15px 0 75px;
  &__title {
    font-weight: 700;
    margin: 0;
  }
  &__img-wrapper {
    position: relative;
    height: 405px;
    margin: 60px 0 0;
  }
  &__img {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
  }
  &__instructions {
    color: #93A0A9;
    font-style: italic;
    font-size: 14px;
    margin: 77px 0 25px;
  }
  &__description {
    margin: 41px 0 70px;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    p {
      margin: 0 0 20px;
    }
  }
  &__chars {
    font-size: 20px;
    font-weight: 700;
    margin: 0;
  }
}
.amount-control {
  float: right;
  margin: 0 10px 0 0;
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #D7E2EA;
  padding: 2px;
  &__plus,
  &__minus {
    float: left;
    display: block;
    color: #95A2AB;
    background: #DAE0E5;
    height: 34px;
    width: 34px;
    line-height: 34px;
    font-size: 40px;
    text-align: center;
    cursor: pointer;
    &:hover {
      background: darken(#DAE0E5, 5%);
    }
  }
  &__amount {
    float: left;
    display: block;
    height: 34px;
    border: none;
    width: 47px;
    padding: 0 5px;
    color: #93A0A9;
    font-size: 24px;
    font-family: 'Open Sans', sans-serif;
    text-align: center;
  }
}
.chars-table {
  margin: 25px 0 0;
  padding: 0;
  list-style-type: none;
  font-family: 'Open Sans', sans-serif;
  li {
    //border-bottom: 2px dotted #93A0A9;
    margin: 0 0 8px;
    @include clearfix();
  }
  &__key {
    text-align: left;
    width: 50%;
    float: left;
    color: #93A0A9;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      height: 2px;
      width: 200%;
      bottom: 5px;
      left: 0;
      border-bottom: 2px dotted #93A0A9;
    }
    span {
      display: inline-block;
      position: relative;
      background: #ffffff;
      z-index: 1;
      padding-right: 10px;
      white-space: nowrap;
    }
  }
  &__value {
    text-align: right;
    width: 50%;
    float: left;
    color: #403E3E;
    span {
      display: inline-block;
      position: relative;
      background: #ffffff;
      z-index: 1;
      padding-left: 10px;
    }
  }
}
.related {
  text-align: center;
  padding: 60px 0 80px;
  &__title {
    margin: 0 0 45px;
    display: inline-block;
    font-weight: 700;
    font-family: 'fontin sans', sans-serif;
    font-size: 36px;
    border-bottom: 1px solid #D1AEAB;
  }
  background: #ffffff; /* Old browsers */
  background: -moz-linear-gradient(top,  #F8F4F0 0%, #f8f4ef 30%, #c9d5d4 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  #F8F4F0 0%, #f8f4ef 30%,#c9d5d4 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  #F8F4F0 0%, #f8f4ef 30%,#c9d5d4 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#F8F4F0', endColorstr='#c9d5d4',GradientType=0 ); /* IE6-9 */
  box-shadow: 0 -3px 16px 0 #DDDEDF;
  .to-cart {
    @media (max-width: $screen-md-max) {
      padding-left: 40px;
      padding-right: 15px;
    }
  }
}
.related-divider {
  background: #ffffff;
  width: 100%;
  display: block;
}
.select {
  vertical-align: middle;
  display: inline-block;
  font-style: normal;
  width: 210px;
  height: 27px;
  line-height: 25px;
  color: #93A0A9;
  font-size: 14px;
  position: relative;
  cursor: pointer;
  padding: 0 15px;
  margin: 0 10px 0 0;
  border: 1px solid #D7E2EA;
  &__options {
    cursor: pointer;
    top: 26px;
    left: 0;
    right: 0;
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: none;
    position: absolute;
    background: #F4F4F4;
    z-index: 5;
    li {
      width: 100%;
      &:hover {
        background: darken(#ffffff, 20%);
        color: #ffffff;
      }
      span {
        padding: 0 15px;
      }
    }
    .checked {
      display: none;
    }
  }
  &__icon {
    color: #93A0A9;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 12px;
  }
  &.active {
    .select__options {
      display: block;
    }
    .select__icon {
      @include scaleY(-1);
    }
  }
}
