$rouble-font-directory: '/fonts/' !default;

@font-face {
  font-family: "Rouble";
  src: url('../#{$rouble-font-directory}rouble.eot?') format('eot'),
    url('../#{$rouble-font-directory}rouble.otf') format('opentype'),
    url('../#{$rouble-font-directory}rouble.woff') format('woff'),
    url('../#{$rouble-font-directory}rouble.ttf')  format('truetype'),
    url('../#{$rouble-font-directory}rouble.svg#ALSRubl') format('svg');
}
span.rouble {
  font-family: "Rouble";
  &:before {
    content: "q";
  }
}
